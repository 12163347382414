const zh = {
  linkWattet: '连接钱包',
  intro: 'Shib介绍',
  quickGet: '快速了解shib未来价值',
  letGo: "let's go",
  oneIntro: 'Shib-ONE介绍',
  explore: '让我们一起探索shib生态，',
  make: '打造',
  cyclic: 'shib生态循环',
  earn: '赚Shib',
  global: '全球唯一官方shib生态社区，让我们一起赚更多的shib，',
  wealth: '将是你不能错过的财富',
  community: '社区权益',
  share: '节点分红：',
  destroy: '销毁数量：',
  address: '持币地址：',
  ntf: 'NTF卡牌',
  only: 'shib生态2.0将产生唯一的，限量的，仅有的nft卡牌，未来价值不可估量，值的你收藏拥有。',
  coop: '合作机构',
  invest: '投入',
  bind: '绑定',
  receive: '领取'
};

export default zh;
