const en = {
  linkWattet: 'Link Wattet',
  intro: 'Introduction of shib',
  quickGet: "Learn about shib's future value quickly",
  letGo: "let's go",
  oneIntro: 'Introduction of shib-ONE',
  explore: "Let's explore shib ecology,",
  make: 'Create',
  cyclic: 'shib ecological cycle',
  earn: 'Earn shib',
  global: "The world's only official shib ecological community, let's earn more Shibs together,",
  wealth: "Will be a treasure you can't miss",
  community: 'Community rights and interests',
  share: 'Node dividend:',
  destroy: 'Quantity destroyed:',
  address: 'Holding address:',
  ntf: 'NTF card',
  only: 'shib Eco 2.0 will produce unique, limited, and only nft cards that will be invaluable to your collection in the future.',
  coop: 'Cooperative agency',
  invest: 'invest',
  bind: 'bind',
  receive: 'receive'
};

export default en;
