import './App.scss';
import { ethers } from 'ethers';
import { useRoutes, useNavigate } from 'react-router-dom';
import routes from './router';
import { Provider } from './context';
import { IntlProvider, FormattedMessage } from 'react-intl';
import messages from './lang';
import { useState, useEffect, useRef } from 'react';
import { ConfigProvider, Button, message } from 'antd';
import { renderParticles } from './hooks/renderParticles';
import GameABI from './hardhat/artifacts/contracts/game.sol/Game.json';
import TokenABI from './hardhat/artifacts/contracts/game.sol/IERC20.json';

let GameAddr = '0x49d88f2423Fe98A142698db32365f5041a9B15E2';
let One = '0xab59F030703031a3Ff805B4E6512B5466be6ee93';
let Shib = '0xbA40641f7F0A7a1F69D86Ca0540498cc17527663';

let GameAddrContract;
let OneContract;
let ShibContract;

let ChainInfos;
let ChainInfo = 97;
let WalletProviders;
let Accounts;
let MinimumInput;
// let _sum = ethers.BigNumber.from('0');
let arr = {

};

export default function App() {
  const element = useRoutes(routes);
  const navigate = useNavigate();
  const [locale, setLocale] = useState(localStorage.getItem('locale') || 'zh');
  const [ConnectWalletLoading, setConnectWalletLoading] = useState(false);
  const [Account, setAccount] = useState();
  const [Bl, setBl] = useState(['--', '--']);
  const [UserInfo, setUserInfo] = useState(['--', '--', '--', '--', '--', '--', '--', '--']); //团队等级,是否是节点地址,我直推的人数,每个直推地址的伞下业绩,伞下总业绩(每个直推地址的伞下业绩相加),我的上级地址,总共可获得的奖励数量,已提取的数量
  const [Gameinfo, setGameinfo] = useState(['--', '--', '--', false]); //指定地址每天可得的静态收益,游戏静态收益率,最小投入数量,是否存在上级
  const [ClaimableIncome, setClaimableIncome] = useState([0, 0, 0, 0]);
  const [BetLoading, setBetLoading] = useState(false);
  const [BindRefDisabled, setBindRefDisabled] = useState(false);
  const canvasRef = useRef();

  useEffect(() => {
    renderBg();
  }, []);

  // 生成背景
  const renderBg = () => {
    const particleCanvas = canvasRef.current;

    renderParticles(particleCanvas, {
      particleCount: 40,
      particleColors: ['#f0a93a'],
      particleMaxSize: 2,
      particleMoveRate: 0.2
    });
  };

  // 切换语言
  const onToggleLocale = () => {
    let value = locale === 'zh' ? 'en' : 'zh';
    localStorage.setItem('locale', value);
    setLocale(value);
  };

  function isMetaMaskInstalled() {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }

  let GetAccount = async () => {
    try {
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((accounts) => {
          Accounts = accounts[0];
          //setAccounts(Accounts);
          setAccount(Accounts.substring(0, 6) + '...' + Accounts.substring(35, 42).toString());
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  let ConnectWallet = async () => {
    try {
      if (isMetaMaskInstalled()) {
        if (ChainInfos && ChainInfo == ChainInfos.chainId.toString()) {
          return;
        }
        setConnectWalletLoading(true);
        WalletProviders = new ethers.providers.Web3Provider(window.ethereum);

        await GetAccount();
        await chainChanged();
        setConnectWalletLoading(false);
        //await GetGameInfo();

        //setRefURL(window.location.host + "/#" + Accounts);
        if (ChainInfo == 56) {
          WalletProviders.on('block', async () => {
            await GetGameInfo();
          });
        }

        await window.ethereum.on('chainChanged', async function () {
          WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
          ChainInfos = await WalletProviders.getNetwork();
          setAccount(Accounts.substring(0, 6) + '...' + Accounts.substring(35, 42).toString());
          await chainChanged();
          await GetGameInfo();
        });
        window.ethereum.on('accountsChanged', async function () {
          await GetAccount();
          await GetGameInfo();
        });
      } else {
        message.warning(locale == 'zh' ? '没有检测到钱包插件!' : 'Please install MetaMask');
        return;
      }
    } catch (err) {
      setConnectWalletLoading(false);
      console.log(err);
    }
  };

  let chainChanged = async () => {
    try {
      WalletProviders = new ethers.providers.Web3Provider(window.ethereum);
      ChainInfos = await WalletProviders.getNetwork();
      //console.log('ChainInfos.chainId', ChainInfos.chainId.toString(), ChainInfo);

      if (ChainInfos.chainId != ChainInfo) {
        setAccount(locale == 'zh' ? '请选择正确的网络!' : 'Please select the correct network !');
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + ChainInfo.toString(16) }]
        });
      } else {
        setAccount(Accounts.substring(0, 6) + '...' + Accounts.substring(35, 42).toString());
      }
    } catch (err) {
      console.log(err);
    }
  };

  let baseInfo = async () => {
    try {
    } catch (err) {
      console.log(err);
    }
  };

  let joinGame = async (_value) => {
    try {
      if (!_value || _value <= 0) {
        return;
      }
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let _values = ethers.BigNumber.from(_value.toString()).mul(ethers.BigNumber.from('1000000000000000000'));
      if (ethers.BigNumber.from(MinimumInput.toString()).gt(_values)) {
        message.warning(locale == 'zh' ? '投入必须大于最低限制' : 'Input must be greater than the minimum limit');
        return;
      }

      setBetLoading(true);

      let app_ = await ShibContract.approve(GameAddr, _values.toString());
      await app_.wait();

      let tx = await GameAddrContract.joinGame(_values.toString());

      await tx.wait();
      setBetLoading(false);
    } catch (err) {
      setBetLoading(false);
      console.log(err);
    }
  };

  let BindRef = async (_addr) => {
    try {
      if (!_addr) {
        locale == 'zh' ? message.warning('地址不能为空') : message.warning('Address cannot be empty');
        return;
      }
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let tx = await GameAddrContract.bindRef(_addr);
      await tx.wait();
    } catch (err) {
      locale == 'zh' ? message.warning('邀请地址未注册') : message.warning('The invitation address is not registered');
      console.log(err);
    }
  };

  //推荐收益
  let WithdrawalOfRefIncome = async () => {
    try {
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let tx = await GameAddrContract.WithdrawalOfRefIncome();
      await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };

  //静态收益
  let WithdrawalOfStaticIncome = async () => {
    try {
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let tx = await GameAddrContract.WithdrawalOfStaticIncome();
      await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };

  //帕点收益
  let WithdrawalOfPascalIncome = async () => {
    try {
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let tx = await GameAddrContract.WithdrawalOfPascalIncome();
      await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };

  //团队收益
  let WithdrawalOfTeamIncome = async () => {
    try {
      if (!WalletProviders) {
        message.warning(locale == 'zh' ? '请先连接钱包!' : 'Please connect the wallet first.');
        return;
      }
      let tx = await GameAddrContract.WithdrawalOfTeamIncome();
      await tx.wait();
    } catch (err) {
      console.log(err);
    }
  };


  let GetGameInfo = async () => {
    try {
      OneContract = new ethers.Contract(One, TokenABI.abi, WalletProviders.getSigner());
      ShibContract = new ethers.Contract(Shib, TokenABI.abi, WalletProviders.getSigner());
      GameAddrContract = new ethers.Contract(GameAddr, GameABI.abi, WalletProviders.getSigner());
      let OneBl = await OneContract.balanceOf(Accounts);
      let ShibBl = await ShibContract.balanceOf(Accounts);
      OneBl = ethers.utils.formatUnits(OneBl.toString(), 18);
      ShibBl = ethers.utils.formatUnits(ShibBl.toString(), 18);
      console.log('OneBl', OneBl, "ShibBl.toString()", ShibBl, ShibBl.match(/^\d+(?:\.\d{0,2})?/).toString())
      MinimumInput = await GameAddrContract.queryMinimumInput();
      //shib和one余额
      setBl([OneBl.match(/^\d+(?:\.\d{0,2})?/).toString(), ShibBl.match(/^\d+(?:\.\d{0,2})?/).toString()]);

      //团队等级
      let queryTeamLevel = await GameAddrContract.queryTeamLevel(Accounts);
      //是否是节点地址
      let IsNodeAddr = await GameAddrContract.queryIsNodeAddr(Accounts);
      //我直推的人数
      let RecommendedNumberSum = await GameAddrContract.RecommendedNumberSum(Accounts);
      //每个直推地址的伞下业绩
      let RecommendedSumlist = await GameAddrContract.RecommendedSum(Accounts);
      //let maps = new Map();
      let _sum = ethers.BigNumber.from('0');
      for (let i = 0; i < RecommendedSumlist.length; i++) {
        let value_ = await GameAddrContract.RecommendedAchievementSum(RecommendedSumlist[i]);
        arr[RecommendedSumlist[i].toString().substring(0, 6) + '...' + RecommendedSumlist[i].toString().substring(35, 42)] = Processings(value_.toString());
        //maps.set(RecommendedSumlist[i], value_);
        //伞下总业绩(每个直推地址的伞下业绩相加)

        _sum = _sum.add(ethers.BigNumber.from(value_.toString()));

      }
      _sum = Processings(_sum);
      // _sum = _sum.add('1');
      // console.log('_sum', _sum.toString())

      //测试数据
      let arr1 = "0x0000000000000000000000000000000000000001"
      let arr2 = "0x0000000000000000000000000000000000000002"
      let arr3 = "0x0000000000000000000000000000000000000003"
      let arr4 = "0x0000000000000000000000000000000000000004"
      let arr5 = "0x0000000000000000000000000000000000000005"
      let arr6 = "0x0000000000000000000000000000000000000006"


      arr[arr1.toString().substring(0, 6) + '...' + arr1.toString().substring(35, 42)] = Processings(5000000)
      arr[arr2.toString().substring(0, 6) + '...' + arr2.toString().substring(35, 42)] = Processings(5000000)
      arr[arr3.toString().substring(0, 6) + '...' + arr3.toString().substring(35, 42)] = Processings(15000000)
      arr[arr4.toString().substring(0, 6) + '...' + arr4.toString().substring(35, 42)] = Processings(15000000)
      arr[arr5.toString().substring(0, 6) + '...' + arr5.toString().substring(35, 42)] = Processings(15000000)
      arr[arr6.toString().substring(0, 6) + '...' + arr6.toString().substring(35, 42)] = Processings(15000000)

      ///////////


      //我的上级地址
      let querySuperior = await GameAddrContract.querySuperior(Accounts);
      if (querySuperior == '0x0000000000000000000000000000000000000000') {
        setBindRefDisabled(false);
        querySuperior = querySuperior.toString();
      } else {
        querySuperior = querySuperior.substring(0, 6) + '...' + querySuperior.substring(35, 42).toString();
      }

      let buyAmount = await GameAddrContract.buyAmount(Accounts);
      //console.log('buyAmount', buyAmount);
      let withdrawalAmount = await GameAddrContract.withdrawalAmount(Accounts);
      //console.log('withdrawalAmount', withdrawalAmount);

      //团队等级,是否是节点地址,我直推的人数,每个直推地址的伞下业绩,伞下总业绩(每个直推地址的伞下业绩相加),我的上级地址,总共可获得的奖励数量,已提取的数量
      setUserInfo([queryTeamLevel.toString(), IsNodeAddr.toString(), RecommendedNumberSum.toString(), arr, _sum, querySuperior.toString(), Processings(buyAmount[1].toString()), Processings(withdrawalAmount.toString())]);
      //console.log('gameinfo1')
      //预估指定地址每天可得的静态收益
      let StaticRewardsDay = await GameAddrContract.StaticRewardsDay(Accounts);
      //console.log('StaticRewardsDay', StaticRewardsDay)
      //查看静态收益率
      let queryStaticRewardsFee = await GameAddrContract.queryStaticRewardsFee();

      //console.log('gameinfo2')

      let MinimumInput_ = ethers.BigNumber.from(MinimumInput.toString()).div(ethers.BigNumber.from('1000000000000000000'));
      //指定地址每天可得的静态收益,游戏静态收益率,最小投入数量
      setGameinfo([Processings(StaticRewardsDay.toString()), queryStaticRewardsFee.toString(), MinimumInput_.toString()]);

      let _queryRewardAmount = await GameAddrContract.queryRewardAmount(Accounts);
      //console.log([Processings(_queryRewardAmount[0].toString()), Processings(_queryRewardAmount[1].toString()), Processings(_queryRewardAmount[2].toString()), Processings(_queryRewardAmount[3].toString())])
      setClaimableIncome([Processings(_queryRewardAmount[0].toString()), Processings(_queryRewardAmount[1].toString()), Processings(_queryRewardAmount[2].toString()), Processings(_queryRewardAmount[3].toString())]);

      //console.log('gameinfo')
    } catch (err) {
      console.log(err);
    }
  };
  let Processing = (x) => ethers.BigNumber.from(x).div(ethers.BigNumber.from('1000000000000000000')).toNumber().toFixed(3);
  let Processings = (x) => ethers.utils.formatUnits(x.toString().match(/^\d+(?:\.\d{0,2})?/).toString(), 18);

  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
      <ConfigProvider theme={{ token: { colorError: '#F0A93A' } }}>
        <div>
          <div className="app-page w-100 h-100 flex-vertical">
            <header className="flex-header flex-item align-items-center p-3">
              <div className="flex-label d-flex align-items-center cursor-pointer" onClick={() => navigate('/')}>
                <img className="logo" src={require('./assets/img/logo.png')} alt="" />
                <div className="color-warning text-plus text-bold ml-3">Shib-ONE</div>
              </div>
              <div className="flex-content d-flex align-items-center justify-content-end pl-2">
                <Button danger onClick={ConnectWallet} loading={ConnectWalletLoading}>
                  {Account ? Account : <FormattedMessage id="linkWattet" />}
                  {/* <FormattedMessage id="linkWattet" /> */}
                </Button>
              </div>
              <div className="flex-label cursor-pointer ml-2-5 flex-center-center" onClick={onToggleLocale}>
                {locale === 'en' ? (
                  <svg t="1677825696233" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4524" width="1.8rem" height="1.8rem">
                    <path d="M160 144a32 32 0 0 0-32 32V864a32 32 0 0 0 32 32h688a32 32 0 0 0 32-32V176a32 32 0 0 0-32-32H160z m0-64h688a96 96 0 0 1 96 96V864a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V176a96 96 0 0 1 96-96z" fill="#D2D6E1" p-id="4525"></path>
                    <path d="M482.176 262.272h59.616v94.4h196v239.072h-196v184.416h-59.616v-184.416H286.72v-239.04h195.456V262.24z m-137.504 277.152h137.504v-126.4H344.64v126.4z m197.12 0h138.048v-126.4H541.76v126.4z" fill="#D2D6E1" p-id="4526"></path>
                  </svg>
                ) : (
                  <svg t="1677825731172" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5635" width="1.8rem" height="1.8rem">
                    <path d="M229.248 704V337.504h271.744v61.984h-197.76v81.28h184v61.76h-184v99.712h204.768V704h-278.72z m550.496 0h-70.24v-135.488c0-28.672-1.504-47.232-4.48-55.648a39.04 39.04 0 0 0-14.656-19.616 41.792 41.792 0 0 0-24.384-7.008c-12.16 0-23.04 3.328-32.736 10.016-9.664 6.656-16.32 15.488-19.872 26.496-3.584 11.008-5.376 31.36-5.376 60.992V704h-70.24v-265.504h65.248v39.008c23.168-30.016 52.32-44.992 87.488-44.992 15.52 0 29.664 2.784 42.496 8.352 12.832 5.6 22.56 12.704 29.12 21.376 6.592 8.672 11.2 18.496 13.76 29.504 2.56 11.008 3.872 26.752 3.872 47.264V704z" fill="#D2D6E1" p-id="5636"></path>
                    <path d="M160 144a32 32 0 0 0-32 32V864a32 32 0 0 0 32 32h688a32 32 0 0 0 32-32V176a32 32 0 0 0-32-32H160z m0-64h688a96 96 0 0 1 96 96V864a96 96 0 0 1-96 96H160a96 96 0 0 1-96-96V176a96 96 0 0 1 96-96z" fill="#D2D6E1" p-id="5637"></path>
                  </svg>
                )}
              </div>
            </header>
            <main className="flex-content no-scroll">
              <Provider value={{ locale, WalletProviders, Bl, UserInfo, Gameinfo, ClaimableIncome, GetGameInfo, joinGame, BindRef, WithdrawalOfRefIncome, WithdrawalOfStaticIncome, WithdrawalOfPascalIncome, WithdrawalOfTeamIncome, arr }}>{element}</Provider>
            </main>
          </div>
          <canvas id="particles" ref={canvasRef} />
        </div>
      </ConfigProvider>
    </IntlProvider>
  );
}
