import Home from '../views/Home';
import Show from '../views/Show';

const routes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/show',
    element: <Show />
  }
];

export default routes;
