import './index.scss';
import { FormattedMessage } from 'react-intl';
import myContext from '../../context';
import { useEffect, useState, useContext, useReducer } from 'react';
import { ConfigProvider, Button, Input, Tag } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import { splitArr } from '../../hooks/splitArr';


export default function Index() {
  const { WalletProviders, Bl, UserInfo, Gameinfo, ClaimableIncome, GetGameInfo, joinGame, BindRef, WithdrawalOfRefIncome, WithdrawalOfStaticIncome, WithdrawalOfPascalIncome, WithdrawalOfTeamIncome,arr } = useContext(myContext);
  const [BetV, setBetV] = useState();
  const [BindRefAddr, setBindRefAddr] = useState();
  

  const initialLoading = {
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false
  };

  const loadingReducer = (state, action) => {
    const changeState = {
      [`loading${action.type}`]: action.value
    };
    return { ...state, ...changeState };
  };

  const [state, dispatch] = useReducer(loadingReducer, initialLoading);

  const withdraw = async (type) => {
    dispatch({ type, value: true });
    if(type == 1){
      await WithdrawalOfRefIncome();
    }else if(type == 2){
      await WithdrawalOfStaticIncome();
    }else if(type == 3){
      await WithdrawalOfPascalIncome();
    }else if(type == 4){
      await WithdrawalOfTeamIncome();
    }
    
    dispatch({ type, value: false });
  };

  

  useEffect(() => {
    if (!WalletProviders) {
      return;
    }

  }, [WalletProviders, UserInfo]);


  return (
    <div className="show-page px-3 pb-3">
      <Button
        className="w-100 mb-3"
        loading={false}
        danger
        onClick={async () => {
          await GetGameInfo();
        }}
      >
        刷新
      </Button>

      <div className="bg-deep round-lg p-3-5">
        <div className="color-light mb-2 flex-item">
          <div className="flex-content text-des">预估每天可得的静态收益</div>
          <div className="flex-label color-white">
            <Tag color="#F0A93A">{Gameinfo[0]}</Tag>
          </div>
        </div>
        <div className="color-light mb-2 flex-item">
          <div className="flex-content text-des">游戏静态收益率</div>
          <div className="flex-label color-white">
            <Tag color="#FF5500">{Gameinfo[1]}</Tag>
          </div>
        </div>
        <div className="color-light flex-item">
          <div className="flex-content text-des">最小投入数量</div>
          <div className="flex-label color-white">
            <Tag color="#373646">{Gameinfo[2]}</Tag>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-center">
          <svg t="1679135941677" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11948" width="2rem" height="2rem">
            <path d="M267.9 237.3H960c21.4 0 38.8 17.4 38.8 38.8v615c0 17.5-21.4 26-33.4 13.3l-87.6-92.2c-24.2-25.5-57.8-39.9-92.9-39.9h-517c-21.4 0-38.8-17.4-38.8-38.8V276.1c0.1-21.5 17.4-38.8 38.8-38.8z" fill="#FFCA28" p-id="11949"></path>
            <path d="M267.9 237.3c-21.4 0-38.8 17.4-38.8 38.8v380.8l561.2-419.5H267.9zM998.9 276.1c0-21.4-17.4-38.8-38.8-38.8H794l204.8 274V276.1z" fill="#FFCA28" p-id="11950"></path>
            <path d="M792.7 235.5l-2.3 1.8h3.6zM267.9 772.3h517c35.1 0 68.7 14.4 92.9 39.9l87.6 92.2c12.1 12.7 33.4 4.2 33.4-13.3V511.3L794 237.3h-3.6L229.2 656.8v76.7c0 21.5 17.3 38.8 38.7 38.8z" fill="#FFB806" p-id="11951"></path>
            <path d="M756.1 113.5H63.9c-21.4 0-38.8 17.4-38.8 38.8v615c0 17.5 21.4 26 33.4 13.3l87.6-92.2c24.2-25.5 57.8-39.9 92.9-39.9h517c21.4 0 38.8-17.4 38.8-38.8V152.3c0-21.4-17.3-38.8-38.7-38.8z" fill="#fec467" p-id="11952" data-spm-anchor-id="a313x.7781069.0.i5" className=""></path>
          </svg>
          <div className="ml-2">收益信息</div>
        </div>
        <div className="bg-deep round-lg p-3-5 mt-2">
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">我的shib余额</div>
            <div className="flex-label color-white">{Bl[1]}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">我的one余额</div>
            <div className="flex-label color-white">{Bl[0]}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">团队等级</div>
            <div className="flex-label color-white">{UserInfo[0]}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">是否是节点地址</div>
            <div className="flex-label color-white">{UserInfo[1]}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">我直推的人数</div>
            <div className="flex-label color-white">
              <Tag color="#54af2f">{UserInfo[2]}</Tag>
            </div>
          </div>
          <div className="bg-opacity-1 px-3 py-2 round-sm mb-2">
            <div className="text-des color-success text-center mb-2">每个直推地址的伞下业绩</div>

            <Carousel autoPlay infiniteLoop interval="100000" showIndicators={false} showStatus={false} showThumbs={false}>
              {splitArr(Object.entries(arr), 3).map((item, index) => {
                return (
                  <div key={index} className="px-0-5">
                    {item.map((subItem, subIndex) => {
                      return (
                        <div key={subIndex} className={`color-light flex-item ${subIndex ? 'mt-2' : ''}`}>
                          <div className="flex-content text-des text-left">{subItem[0]}</div>
                          <div className="flex-label color-white">{subItem[1]}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Carousel>
            {/* <div className="px-5-5">
              {Object.entries(UserInfo[3]).map((item, index) => {
                return (
                  <div key={index} className={`color-light flex-item ${index ? 'mt-2' : ''}`}>
                    <div className="flex-content text-des text-left">{item[0]}</div>
                    <div className="flex-label color-white">{item[1]}</div>
                  </div>
                );
              })}
            </div> */}
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">伞下总业绩</div>
            <div className="flex-label color-white">{UserInfo[4]}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">我的上级地址</div>
            <div className="flex-label color-white">{UserInfo[5].substring(0, 6) + '...' + UserInfo[5].substring(35, 42).toString()}</div>
          </div>
          <div className="color-light mb-2 flex-item">
            <div className="flex-content text-des">总共可获得的奖励数量</div>
            <div className="flex-label color-white">{UserInfo[6]}</div>
          </div>
          <div className="color-light flex-item">
            <div className="flex-content text-des">已提取的数量</div>
            <div className="flex-label color-white">{UserInfo[7]}</div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex align-items-center justify-content-center">
          <svg t="1679135717609" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4087" width="2rem" height="2rem">
            <path d="M512 509.1m-444.4 0a444.4 444.4 0 1 0 888.8 0 444.4 444.4 0 1 0-888.8 0Z" fill="#FAB001" p-id="4088"></path>
            <path d="M887.7 459.1c0-132.5-58-251.3-150-332.7C671.6 87.2 594.4 64.7 512 64.7c-245.4 0-444.4 199-444.4 444.4 0 132.5 58 251.3 150 332.7 66.2 39.1 143.3 61.7 225.8 61.7 245.4 0 444.3-199 444.3-444.4z" fill="#F9E48A" p-id="4089"></path>
            <path d="M568 102.5c-245.4 0-444.4 199-444.4 444.4 0 122.5 49.7 233.4 130 313.8 57.6 27.3 121.8 42.8 189.8 42.8 245.4 0 444.4-199 444.4-444.4 0-122.5-49.7-233.4-130-313.8-57.6-27.3-121.9-42.8-189.8-42.8z" fill="#F0A93A" p-id="4090"></path>
            <path
              d="M704.4 616.4c0 28.6-4.9 44.6-25.2 54.3-15.4 7.3-35 9.4-60.2 10-11.8 0.3-22.1-7.7-25.4-19-0.4-1.5-0.9-3.1-1.4-4.6-5.3-16.7 7.3-33.6 24.8-33.6 8 0 14.3-0.1 17.1-0.3 7.8 0 9.7-1.9 9.7-8.2V514.3c0-14.3-11.6-25.9-25.9-25.9h-36.4c-14.3 0-25.9 11.6-25.9 25.9v195.5c0 14.3-11.6 25.9-25.9 25.9H521c-14.3 0-25.9-11.6-25.9-25.9V514.3c0-14.3-11.6-25.9-25.9-25.9h-33c-14.3 0-25.9 11.6-25.9 25.9v141.6c0 14.3-11.6 25.9-25.9 25.9h-7.3c-14.3 0-25.9-11.6-25.9-25.9V458c0-14.3 11.6-25.9 25.9-25.9h92.2c14.3 0 25.9-11.6 25.9-25.9v-10.9c0-14.8-12.4-26.6-27.2-25.9-38.8 1.7-77.5 2.7-114.6 3-12.1 0.1-22.9-8.3-25.4-20.1 0-0.2-0.1-0.4-0.1-0.6-3.4-16.1 8.3-31.4 24.8-31.8 118-2.7 245.9-10.6 338.3-22.8 11.6-1.5 22.7 4.9 27.2 15.7l1.1 2.6c6.6 15.8-3.4 33.6-20.4 35.8-36.9 4.9-77.2 8.7-119.3 11.9-13.5 1-23.9 12.3-23.9 25.9v17.3c0 14.3 11.6 25.9 25.9 25.9h97c14.3 0 25.9 11.6 25.9 25.9v158.3z"
              fill="#FFFFFF"
              p-id="4091"
            ></path>
          </svg>
          <div className="ml-2">收益领取</div>
        </div>
        <div className="bg-deep round-lg p-3-5 mt-2">
          <div>
            <Input
              className="mb-2"
              onChange={async (event) => {
                // if (parseFloat(event.target.value).toString() == "NaN") {
                //   return;
                // }
                setBetV(event.target.value);
              }}
            />
            <Button
              className="w-100 linear"
              shape="round"
              loading={false}
              danger
              onClick={async () => {
                await joinGame(BetV);
                
              }}
            >
              <FormattedMessage id="invest" />
            </Button>
          </div>
          <div className="mt-3">
            <div className="color-light mb-2 text-des">绑定上级</div>
            <Input
              className="mb-2 success"
              shape="round"
              disabled={UserInfo[5] == '0x0000000000000000000000000000000000000000' ? false : true}
              onChange={async (event) => {
                setBindRefAddr(event.target.value);
              }}
            />
            <ConfigProvider theme={{ token: { colorError: '#7CC261' } }}>
              <Button
                className="w-100 success"
                shape="round"
                loading={false}
                danger
                onClick={async () => {
                  await BindRef(BindRefAddr);
                }}
              >
                <FormattedMessage id="bind" />
              </Button>
            </ConfigProvider>
          </div>
          <div className="mt-3">
            <div className="color-light mb-2 flex-item">
              <div className="flex-content text-des">可领取的推荐奖励数量</div>
              <div className="flex-label color-white">{ClaimableIncome[0]}</div>
            </div>
            <Button className="w-100" shape="round" loading={state.loading1} danger onClick={() => withdraw(1)}>
              <FormattedMessage id="receive" />
            </Button>
          </div>
          <div className="mt-3">
            <div className="color-light mb-2 flex-item">
              <div className="flex-content text-des">可领取的静态收益数量</div>
              <div className="flex-label color-white">{ClaimableIncome[1]}</div>
            </div>
            <Button className="w-100" shape="round" loading={state.loading2} danger onClick={() => withdraw(2)}>
              <FormattedMessage id="receive" />
            </Button>
          </div>
          <div className="mt-3">
            <div className="color-light mb-2 flex-item">
              <div className="flex-content text-des">可领取的帕点收益</div>
              <div className="flex-label color-white">{ClaimableIncome[2]}</div>
            </div>
            <Button className="w-100" shape="round" loading={state.loading3} danger onClick={() => withdraw(3)}>
              <FormattedMessage id="receive" />
            </Button>
          </div>
          <div className="mt-3">
            <div className="color-light mb-2 flex-item">
              <div className="flex-content text-des">可领取的团队收益</div>
              <div className="flex-label color-white">{ClaimableIncome[3]}</div>
            </div>
            <Button className="w-100" shape="round" loading={state.loading4} danger onClick={() => withdraw(4)}>
              <FormattedMessage id="receive" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
